.footer {
  padding: 2rem 0;
  background-color: black;
}

.hide {
  position: relative;
  font-family: sans-serif;
  text-transform: uppercase;
  font-size: 1.5em;
  letter-spacing: 4px;
  overflow: hidden;
  background: linear-gradient(90deg, #000, #0fb9b4, #000);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 3s linear infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
}

@keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}

/* Flexbox to evenly distribute space */
.footer__content {
  display: flex; 
  flex-direction: column; 
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  text-align: center;
  padding: 0 1rem; 
}

.footer__content--item {
  flex: 1; /* Make all items take equal space */
}

.payment-methods {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.payment-icons {
  display: flex;
  gap: 1rem; /* Equal gap between images */
  justify-content: center; /* Center the images */
  align-items: center;
  width: 100%; 
}

.payment-icon {
  border-radius: 10px;
  max-width: 80px; 
  max-height: 50px; /* Keep images uniformly sized */
  transition: transform 0.3s ease;
}

.payment-icon:hover {
  transform: scale(1.1); 
}

.contact-info {
  text-align: center;
}

.socials {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 30px;
  margin-top: 2rem; 
}

.socials li {
  margin: 0 20px;
}

.socials a {
  color: #fff;
  transition: color 0.3s ease, transform 0.3s ease; /* Smooth transitions for hover effects */
}

.socials a:hover {
  color: #0fb9b4;
  transform: rotate(360deg);
}

.footer__navlink {
  font-size: 2rem;
  display: block;
  margin-bottom: 1rem;
}

.footer__title {
  margin-bottom: 1.6rem;
}

.footer__links li {
  margin-bottom: 1rem;
}

.footer__links li a {
  transition: var(--transition);
}

.footer__links li a:hover {
  color: var(--robin-blue-color);
}

/* Responsive Adjustments */
@media screen and (min-width: 768px) {
  .footer__content {
    flex-direction: row; /* Align items in a row for larger screens */
    gap: 2rem; /* Adjust gap for larger screens */
    align-items: flex-start; /* Align items to the top */
  }

}

@media screen and (min-width: 992px) {
  .footer__content {
    gap: 3rem; /* Adjust gap for larger screens */
  }
}

@media screen and (min-width: 1200px) {
  .footer__content {
    gap: 4rem;
  }
}
