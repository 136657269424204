   a {
    text-decoration: none;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  
  .about-us {
    overflow-x: hidden; /* Prevent horizontal scrolling */
  }

  /* About Section */
  .about-section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 60px 0;
    background: #f8f9fa;
  }
  
  .about-section .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
  }
  
  .about-section .content {
    max-width: 600px;
    flex: 1;
    padding: 20px;
  }
  
  .about-section .content h2 {
    font-size: 2.2em;
    color:#0fb9b4;
    margin-bottom: 20px;
    position: relative;
    text-align: center;
  }
  
  .about-section .content h2::after {
    content: "";
    position: absolute;
    width: 50px;
    height: 4px;
    background-color: #0fb9b4;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .about-section .content p {
    font-size: 1.1em;
    line-height: 1.8;
    margin-bottom: 20px;
    text-align: justify;
  }
  
  .about-section .image {
    flex: 1;
    padding: 20px;
  }
  
  .about-section .image img {
    max-width: 100%;
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    animation: waveAnimation 5s ease-in-out infinite alternate;
  }
  
  .about-section .image img:hover {
    transform: scale(1.05);
  }
  
  /* Waving Animation */
  @keyframes waveAnimation {
    0% {
      transform: rotate(0deg) translate(0, 0);
    }
    25% {
      transform: rotate(2deg) translate(5px, -5px);
    }
    50% {
      transform: rotate(-2deg) translate(-5px, 5px);
    }
    75% {
      transform: rotate(2deg) translate(5px, -5px);
    }
    100% {
      transform: rotate(0deg) translate(0, 0);
    }
  }
  
  .about-section .learn-more-button {
    display: inline-block;
    padding: 12px 25px;
    background-color: #0fb9b4;
    color: #fff;
    border-radius: 25px;
    font-weight: bold;
    transition: all 0.3s ease;
    text-align: center;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
  
  .about-section .learn-more-button:hover {
    background-color: #0fb9b4;
    transform: translateY(-3px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }
  
  

  @media only screen and (max-width: 600px) {
    .learn-more-button {
      margin-left: 19%;
    }
  }