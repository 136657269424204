.skill-section .content h2 {
    font-size: 2.2em;
    color:#0fb9b4;
    margin-bottom: 20px;
    position: relative;
    text-align: center;
  }

  .skill-section .content h2::after {
    content: "";
    position: absolute;
    width: 50px;
    height: 4px;
    background-color: #0fb9b4;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
  }

.skills__item--title{
    margin-bottom: 2rem;
    display: block;
}

.skills__item--progressbar{
    height: 4px;
    width: 100%;
    position: relative;
    background-color: #d8d8d8;
    margin-bottom: 4rem;
}
.skills__item--progressbar div{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: var(--robin-blue-color);
}
.skills__item{
    max-width: 600px;
}



@media only screen and (max-width: 600px) {
    .skills__content{
        margin-top: 6rem;
    }
    
}
@media screen and (min-width: 992px){
    .skills__content{
        grid-template-columns: repeat(2, 1fr);
    }
}