
:root {
  --motion-ease: cubic-bezier(0.68, -0.6, 0.32, 1.6);
  --motion-duration: 0.3s;
}

.contact-container{
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  border-radius: 20px;
  width: 60%;
  padding: 20px;
}
.contacts-section .content h2 {
  font-size: 2.2em;
  color:#0fb9b4;
  margin-bottom: 20px;
  position: relative;
  text-align: center;
}

.contacts-section .content h2::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 4px;
  background-color: #0fb9b4;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
}

.contact__content {
  margin-top: 3.6rem;
}
.contacts .para__text {
  max-width: 700px;
  margin: 0 auto;
}
.form__control {
  font-size: 1.7rem;
  font-family: inherit;
  padding: 1.2rem 2rem;
  width: 100%;
  margin: 1.6rem 0;
}
.form__submit--btn {
  display: block;
  margin: 2rem auto 0 auto;
}
.form-group {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

input,
textarea {
  width: 100%;
  padding: 12px 20px;
  border-radius: 25px;
  border: 1px solid #ddd;
  font-size: 1rem;
  outline: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input:focus,
textarea:focus {
  border-color: #0fb9b4;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

textarea {
  resize: vertical;
  min-height: 100px;
}

.underline {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 2px;
  background: #0fb9b4;
  transition: width 0.3s ease;
  border-radius: 2px;
}

input:focus + .underline,
textarea:focus + .underline {
  width: 97%;
}

.submit-button.loading span {
  background-color: #ff7f7f ;
  cursor: not-allowed;
  visibility: visible;
}

.submit-button.loading svg {
  background-color: #ff7f7f;
  cursor: not-allowed;
  fill: #ff7f7f;
}

.thank-you-message {
  font-size: 1.3rem;
  color: #0fb9b4;
  text-align: center;
  margin-top: 20px;
  animation: fadeIn 0.5s ease forwards;
  opacity: 0;
}

/*   Submit Button   */

.submit-button {
    appearance: none;
    background-color: #0fb9b4;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1;
    padding: 15px 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    text-transform: uppercase;
    width: 100%;
    max-width: 200px; /* Max width for the button */
    margin: 20px auto; /* Center the button horizontally */
}


.submit-button:hover {
  filter: brightness(1.1);
}

.submit-button:active {
  filter: brightness(0.9);
}

.submit-button > .submit-design {
  display: block;
  position: relative;
  transition: transform var(--motion-duration) var(--motion-ease);
  z-index: 1;
}

.submit-button:hover > span {
  transform: scale(1.05);
}

.submit-button:active > span {
  transform: scale(0.95);
}

.submit-button > svg {
  fill: #0fb9b4;
  position: absolute;
  top: -5%;
  left: -5%;
  width: 110%;
  height: 110%;
}

.submit-button > svg > path {
  transition: var(--motion-duration) var(--motion-ease);
}

.submit-button:hover > svg > path {
  d: path(
    "M0,0 C0,-5 100,-5 100,0 C105,0 105,100 100,100 C100,105 0,105 0,100 C-5,100 -5,0 0,0"
  );
}

.submit-button:active > svg > path {
  d: path(
    "M0,0 C30,10 70,10 100,0 C95,30 95,70 100,100 C70,90 30,90 0,100 C5,70 5,30 0,0"
  );
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@media screen and (min-width: 678px) {
  .form__elem.form__elem--2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 3.2rem;
  }
}

@media screen and (min-width: 768px) {
  .contact__content form {
    max-width: 680px;
    margin: 0 auto;
  }
  .contact-container {
    width: 60% !important;
  }
}

@media(max-width: 450px){
  .contact-container {
    width: 90%;
  }
}
