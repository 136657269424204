.work-section{
    background-color: #deded5;
}
.work-section .content h2 {
    font-size: 2.2em;
    color:#0fb9b4;
    margin-bottom: 20px;
    position: relative;
    text-align: center;
  }

  .work-section .content h2::after {
    content: "";
    position: absolute;
    width: 50px;
    height: 4px;
    background-color: #0fb9b4;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
  }

  .work-section .content p {
    font-size: 1.1em;
    line-height: 1.8;
    margin-bottom: 20px;
    text-align:center;
  }

.vid__container{
    margin-top: 40px;
}


