.navbar{
    padding: 1rem 0;
}

.logo {
    width: 53%; /* Default width for mobile devices */
    border-radius: 50%;
    box-shadow: rgba(235, 229, 229, 0.35) 0px 5px 15px;
    transition: width 0.3s ease; /* Smooth transition */
  }
  
ul li a {
    color: white;
    text-decoration: none;
    position: relative;
    padding-bottom: 8px;
}

ul li a::after {
    content: '';
    position: absolute;
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 4px;
    background-color: #0fb9b4;
    transform-origin: bottom right;
    transition: transform 0.5s ease;
    transform: scaleX(0);
}
ul a:hover::after {
    transform-origin: bottom left;
    transform: scaleX(1);
}

.brand__and__toggler{
    align-items: center;
    justify-content: space-between;
    width: 100%;
}
.navbar__collapse{
    display: none;
}
.navbar__collapse .nav__link{
    letter-spacing: 1px;
    transition: var(--transition);
}
.navbar__collapse .nav__link:hover, .nav__link{
    color: var(--robin-blue-color);
}

/* smallscreen */
.navbar__smallscreen{
    position: fixed;
    right: 0;
    top: 0;
    background-color: var(--robin-blue-color);
    height: 100%;
    width: 100%;
    padding: 3rem;
    box-shadow: -3px 0 14px 0 rgba(0, 0, 0, 0.4);
    z-index: 999;
    transition: var(--transition);
}
.navbar__close--btn{
    position: absolute;
    right: 2rem;
    top: 2rem;
    cursor: pointer;
    transition: var(--transition);
}
.navbar__close--btn:hover{
    opacity: 0.9;
}
.navbar__smallscreen .nav__link{
    transition: var(--transition);
    letter-spacing: 1px;
    font-size: 1.8rem;
}
.navbar__smallscreen .nav__link:hover{
    padding-left: 1rem;
}
.navbar__smallscreen .nav__item{
    margin: 2rem 0;
}

/*   Open Logo Modal Preview   */
.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Dark overlay background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the modal is on top */
  }
  
  .modal__content {
    position: relative;
    background-color: #fff;
    border-radius: 12px;
    padding: 20px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    max-width: 90%;
    max-height: 90%;
    overflow: hidden;
    animation: fadeIn 0.3s ease;
  }
  
  .modal__close {
    position: absolute;
    top: 0px;
    right: 2px;
    cursor: pointer;
    background: transparent;
    border: none;
    color: #000;
    transition: opacity 0.3s;
  }
  
  .modal__close:hover {
    opacity: 0.8;
  }
  
  .modal__image {
    width: 100%;
    height: auto;
    border-radius: 5px;
    display: block;
    margin: 0 auto;
    animation: scaleUp 0.3s ease;
  }
  
  /* Keyframe for fade-in animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  /* Keyframe for scale-up animation */
  @keyframes scaleUp {
    from {
      transform: scale(0.8);
    }
    to {
      transform: scale(1);
    }
  }  


  /*     Responsiveness     */
  @media(min-width: 450px){
    .logo {
        width: 34%;
        max-width: 180px;
        margin-bottom: 25px;
      }
}

@media(min-width: 578px){
    .navbar__smallscreen{
        width: 300px;
    }
    .logo {
        width: 35%;
        max-width: 180px;
      }
}

@media (min-width: 768px) {
    /* Medium devices like tablets */
    .logo {
      width: 35%;
      max-width: 180px;
    }
  }
  
@media(min-width: 992px){
    .navbar__open--btn{
        display: none;
    }
    .navbar__collapse{
        display: flex;
    }
    .navbar__collapse .navbar__nav{
        display: flex;
        align-items: center;
    }
    .navbar__collapse .nav__item{
        margin-left: 3.8rem;
    }
    .navbar__smallscreen{
        display: none;
    }
    .logo {
        width: 55%;
      }
}
  
  @media (min-width: 1200px) {
    /* Extra large devices */
    .logo {
      width: 53%;
    }
  }

  