.container{
  max-width: 1177px;
  margin: 0 auto;
  padding: 0 2rem;
}
.section__padding{
  padding: 10rem 0;
}
.flex{
  display: flex;
}
.flex__center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.grid{
  display: grid;
}

/* section */
.section__title{
  margin-bottom: 4rem;
  color: var(--black-color);
  font-weight: 600;
  letter-spacing: 1px;
  font-size: 4rem;
}
.section__padding{
  padding: 10rem 0;
}

/* texts and paras */
.para__text{
  line-height: 1.7;
  font-weight: 300;
  opacity: 0.95;
  letter-spacing: 1px;
}
.text__center{
  text-align: center;
}
.text__upper{
  text-transform: uppercase;
}
.text__cap{
  text-transform: capitalize;
}
.text__blue{
  color: var(--robin-blue-color);
}
.text__dark{
  color: var(--black-color);
}
.text__light{
  color: var(--white-color)!important;
}
.text__grey{
  color: var(--grey-color);
}

/* fonts */
.fw__2{font-weight: 200;}
.fw__2{font-weight: 300;}
.fw__2{font-weight: 400;}
.fw__2{font-weight: 500;}
.fw__2{font-weight: 600;}
.fw__2{font-weight: 700;}
.fw__2{font-weight: 800;}

/* bgs and colors */
.bg__blue{
  background-color: var(--robin-blue-color);
}
.bg__dark{
  background-color: var(--black-color);
}
.bg__light{
  background-color: var(--white-color);
}
.bg__whitesmoke{
  background-color: var(--whitesmoke-color);
}
.bg__grey{
  background-color: var(--grey-color);
}

/* btns */
.btn{
  display: inline-block;
  min-height: 54px;
  line-height: 54px;
  padding: 0 4.7rem;
  color: var(--white-color);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.7rem;
  font-weight: 500;
  transition: var(--transition);
}
.btn__blue{
  background-color: var(--robin-blue-color);
}
.btn__blue:hover{
  background-color: #0fb9b4;
}

/* letter spacing */
.ls__1{
  letter-spacing: 1px;
}
.ls__2{
  letter-spacing: 2px;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .about-section .container,
  .services-section .services,
  .team-section .team-members {
    flex-direction: column;
    align-items: center;
  }

  .about-section .content,
  .about-section .image {
    max-width: 100%;
  }
}