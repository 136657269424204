/* .services__content{
    gap: 4rem;
}
.services__content--item{
    padding: 2rem;
}
.services__content--item .icon{
    width: 60px;
    margin: 0 auto;
    opacity: 0.7;
}
.services__content--item .text{
    margin: 2rem 0;
    opacity: 0.8;
}
.services__content--item .para__text{
    max-width: 400px;
    margin: 0 auto;
}

@media screen and (min-width: 768px){
    .services__content{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (min-width: 992px){
    .services__content{
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (min-width: 1200px) {
    .services__content{
        grid-template-columns: repeat(4, 1fr);
    }
} */

 /* Services Section */
 .services-section {
    padding: 60px 0;
    background: #fff;
  }
  
  .services-section .container {
    text-align: center;
  }
  
  .services-section h2 {
    font-size: 2.1em;
    color:#0fb9b4;
    margin-bottom: 35px;
    position: relative;
    text-align: center;
  }
  
  .services-section .content h2::after {
    content: "";
    position: absolute;
    width: 50px;
    height: 4px;
    background-color: #0fb9b4;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
  }

  .services-section .services {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
  }
  
  .services-section .service {
    flex: 1;
    max-width: 300px;
    background: #f8f9fa;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .services-section .service:hover {
    background: #0fb9b4;
    color: #fff;
    transform: translateY(-10px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }
  
  .services-section .icon {
    font-size: 3em;
    color: #0fb9b4;
    margin-bottom: 20px;
    transition: all 0.3s ease;
  }
  
  .services-section .service:hover .icon {
    color: #fff;
  }
  
  .services-section h3 {
    font-size: 1.4em;
    margin-bottom: 10px;
  }
  
  .services-section p {
    font-size: 1em;
    line-height: 1.6;
  }