@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root{
  --font-base: 'Poppins', sans-serif;
  --robin-blue-color: #10c9c3;
  --black-color: #141414;
  --grey-color: #8e8e8e;
  --white-color: #fff;
  --whitesmoke-color: #f5f5f5;
  --transition: all 300ms ease-in-out;
}

*{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
html{
  font-size: 10px;
}
body{
  font-size: 1.8rem;
  line-height: 1.6;
  font-family: var(--font-base);
  color: var(--black-color);
}

a{
  color: unset;
  text-decoration: none;
}
ul{
  list-style-type: none;
}
img{
  width: 100%;
  display: block;
}
button{
  cursor: pointer;
  outline: 0;
  border: none;
  background-color: transparent;
  font-family: inherit;
  font-size: 1.8rem;
}
input, textarea{
  outline: 0;
  border: none;
  resize: none;
}