/*     Contact Us      */


/* Contact Section */
.contact-section {
    padding: 60px 0;
    background: #0fb9b4;
    color: #fff;
    text-align: center;
  }
  
  .contact-section h2 {
    font-size: 2.2em;
    margin-bottom: 20px;
  }
  
  .contact-section p {
    font-size: 1.2em;
    margin-bottom: 30px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .contact-button {
    display: inline-block;
    padding: 15px 30px;
    background-color: #fff;
    color: #0fb9b4;
    border-radius: 25px;
    font-weight: bold;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .contact-button:hover {
    background-color: #0fb9b4;
    color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
    transform: translateY(-5px);
  }
  
  